import React from "react";
import { Button, Menu } from "antd";
import { FiDelete, FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from '../../assets/logo-branco 2.svg';
import { BiHome } from "react-icons/bi";

export default function Header({ }) {
    return (
        <div className="flex justify-center gap-4">
            <Link to={'/'}>
                <img
                    src={logo}
                    alt="Logo"
                    className="w-40"
                />
            </Link>
        </div>
    );
}
