import React, { useEffect, useState } from 'react';
import { AppRoutes } from './Routes';
import './styles.css';
import './styles/index.css';
import Header from './components/header/header';

export default function App() {


    return (
        <div className="main-sec-all m-auto">
            <div className="p-4 flex flex-col gap-4 text-primary">
            <Header />
                <div
                    className="main-content flex flex-col gap-5 justify-center">
                        
                    <div className="overflow-auto sm:pr-5 sm:pl-5">
                        <div className="basis-1/2 flex flex-col gap-4 h-full">
                            <AppRoutes />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}
