import React from 'react';
import {
    Routes,
    Route, useLocation
} from "react-router-dom";
import ErroPagina from './routes/erropagina';
import Inicio from './routes/page/inicio';

export function AppRoutes({ }) {


    return (
        <Routes>
            <Route path="/" element={<div className='alinhar-centro'><Inicio /></div>} />
            <Route path="*" element={<div className='alinhar-centro'><ErroPagina /></div>} />
        </Routes>
    )
}
