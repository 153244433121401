import React, { useState } from "react";
import { Button, Input, Modal } from "antd";


export default function Inicio({ }) {



    return (

        <div className="sec-geral-top login-section card">
            <div className="form main flex flex-col gap-4">
                <div className='text-center grid gap-5'>
                    <h1 className='text-xl font-semibold'>Uploads</h1>
                    <Button type="primary" htmlType="submit" target="_blank" href="https://receivefiles.de/uploads?url=n5jrvsj5iticerdxfz6qe" className="bg-white text-black font-bold rounded">
                    Footage
                    </Button>

                    <Button type="primary" htmlType="submit" target="_blank" href="https://receivefiles.de/uploads?url=dkevrzoeq7pc1kslsu24" className="bg-white text-black font-bold rounded">
                    Finais
                    </Button>
                </div>
            </div>

        </div>
    )
}
