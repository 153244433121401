import React from "react";
import { Link } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { Button } from 'antd';

function ErroPagina({}) {

    return (
        <div className="sec-geral-top login-section card">
            <div className="form main flex flex-col gap-4 items-center">
                <div className="acesso-negado items-center flex flex-col gap-5">
                    <BiError className='iconc' />
                    <div className="flex-nogap">
                        <h1 className="text-center text-base font-bold">UPS...</h1>
                        <p className="texto-centro">Não conseguimos encontrar o que procuras.</p>
                    </div>
                    <Link to="/">
                        <Button type="primary" className="bg-white text-black font-bold rounded">
                            Voltar ao Início
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ErroPagina